<template>
  <Single
    v-if="!multiple"
    v-model="computedData"
    v-bind:title="title"
    v-bind:icon="icon"
    v-bind:buttonText="buttonText"
    v-bind:dragText="dragText"
    v-bind:orText="orText"
    v-bind:sizeLimit="sizeLimit"
    v-bind:sizeLimitMessage="getSizeLimitMessage"
    v-bind:metaVisible="metaVisible"
    v-bind:acceptedMimeTypes="acceptedMimeTypes"
  />
  <Multiple
    v-else
    v-model="computedData"
    v-bind:title="title"
    v-bind:icon="icon"
    v-bind:buttonText="buttonText"
    v-bind:dragText="dragText"
    v-bind:orText="orText"
    v-bind:sizeLimit="sizeLimit"
    v-bind:sizeLimitMessage="getSizeLimitMessage"
    v-bind:countLimit="countLimit"
    v-bind:countLimitMessage="countLimitMessage"
    v-bind:metaVisible="metaVisible"
    v-bind:acceptedMimeTypes="acceptedMimeTypes"
  />
</template> 

<script>
const Single = () => import("./components/Single/Index");
const Multiple = () => import("./components/Multiple/Index");

export default {
  name: "WeFileUploader",
  components: {
    Single,
    Multiple,
  },
  props: {
    data: {
      files: [],
      filesData: null,
    },
    multiple: {
      default: "",
    },
    title: {
      default: "Dosya Yükle",
    },
    icon: {
      default() {
        let result = "fas fa-cloud-upload-alt fa-3x text-secondary";

        if (this.multiple) {
          result = "fas fa-cloud-upload-alt";
        }

        return result;
      },
    },
    buttonText: {
      default: "Dosya Seç",
    },
    dragText: {
      default: "Dosya Sürükle & Bırak",
    },
    orText: {
      default: "veya",
    },
    sizeLimit: {
      default: "1024",
    },
    sizeLimitMessage: {
      default: "En fazla {limit} KB boyutunda dosya yükleyebilirsiniz.",
    },
    countLimit: {
      default: "10",
    },
    countLimitMessage: {
      default: "En fazla 10 adet dosya yükleyebilirsiniz.",
    },
    metaVisible: {
      default: "true",
    },
    acceptedMimeTypes: {
      default: ".xlsx",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData() {
      return this.data;
    },
    getSizeLimitMessage() {
      return this.sizeLimitMessage.replace("{limit}", this.sizeLimit);
    },
  },
};
</script>

<style lang="scss">
.uploaded-media {
  align-items: center;
}

.uploaded-image-body {
  max-height: 316px;
  overflow-x: hidden !important;
  overflow-y: auto;
}

.uploaded-card,
.uploaded-image-card {
  border-radius: 0.4rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.uploaded-image-card.featured {
  border: 2px solid #2ed573;
  background-color: #2ed5740e;
  margin-top: 10px;
  box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.295);
}

.uploaded-image-card:not(:last-child) {
  margin-bottom: 1rem;
}

.uploaded-media .media-body span {
  font-size: 14px;
  display: block;
}

.uploaded-media img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  background-color: #fff;
  border-radius: 0.25rem !important;
}

.uploaded-media {
  position: relative;
  overflow: hidden;
}
.file-icon {
  z-index: 0;
  position: absolute;
  top: 2px;
  left: -18px;
  transform: rotate(333deg);
  opacity: 0.2;
}
</style>